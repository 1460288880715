import { Flex, Popover } from 'core-ui';
import { DownOutlinedIcon, MenuFoldIcon } from 'src/assets/icons';
import { handleCustomerLogout } from 'src/services/auth';
import { truncateText } from 'src/utils/text';

interface IProps {
  handleOpenDrawer: () => void;
  userName: string;
  userAvatar: string;
}

const Header: React.FC<IProps> = ({
  handleOpenDrawer,
  userName,
  userAvatar
}) => {
  return (
    <div className="relative h-[100%] flex items-center justify-between md:justify-end px-[20px] bg-primary">
      <Flex align="center" className="md:hidden">
        <button onClick={handleOpenDrawer}>
          <img src={MenuFoldIcon} alt="menu fold icon" />
        </button>
      </Flex>
      <h1 className="md:hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[13px] font-semibold">
        製品機器保証サービス
      </h1>
      <div className="flex items-center max-md:hidden">
        <Popover
          placement="bottomRight"
          content={
            <div className="min-w-[70px]">
              <p
                onClick={handleCustomerLogout}
                className="cursor-pointer w-full text-[#EA3D3D] text-xs text-center"
              >
                ログアウト
              </p>
            </div>
          }
        >
          <div className="flex cursor-pointer items-center">
            <div className="w-[34px] h-[34px] rounded-full overflow-hidden">
              <img
                src={userAvatar}
                alt="avatar"
                crossOrigin="anonymous"
                className="w-full h-full object-cover"
              />
            </div>

            <div className="ml-3">
              <p title={userName} className="leading-[20px] font-medium text-white">
                {truncateText(userName, 15)}
              </p>
            </div>

            <div className="ml-2">
              <img src={DownOutlinedIcon} alt="down outlined icon" />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
