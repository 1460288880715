import { notifyMessage } from 'src/constants/message';
import { notification } from 'core-ui';
import { get, isObject, isString, omit, toNumber } from 'lodash';
import { dayjs } from 'core-helpers';
import { apiRequest } from './api';
import { FormInstance } from 'core-ui';
import { DATE_FORMAT } from 'src/constants/date';

export const handleError = (error: any) => {
  console.log({ error });

  const resMessage = error?.response?.data?.errors;
  const errorMessage = isString(resMessage) ? resMessage : notifyMessage.failed;

  notification.error({
    message: errorMessage
  });
};
export function objectToFormData(obj: any, form = null, namespace = '') {
  const formData = form || new FormData();

  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      const value = obj[property];
      // Kiểm tra để loại bỏ giá trị undefined và null
      if (value === undefined || value === null || property === '') continue;

      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (dayjs.isDayjs(value)) {
        formData.append(formKey, value.toString());
      } else if (Array.isArray(value)) {
        value.forEach((element, index) => {
          if (
            typeof element === 'object' &&
            !(element instanceof File) &&
            !(element instanceof Blob)
          ) {
            objectToFormData(element, formData as any, `${formKey}[${index}]`);
          } else {
            formData.append(`${formKey}[${index}]`, element);
          }
        });
      } else if (
        typeof value === 'object' &&
        !(value instanceof File) &&
        !(value instanceof Blob)
      ) {
        objectToFormData(value, formData as any, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }

  return formData;
}
export function trimObjectValues(obj: any) {
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    } else if (
      typeof obj[key] === 'object' &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    ) {
      trimObjectValues(obj[key]);
    }
  }
  return obj;
}

export const removeSearchPage = () => {
  localStorage.removeItem('page')
}

export const saveSearchPage = (page: number) => {
  localStorage.setItem('page', page + '')
}

export const getSearchPage = () => {
  return localStorage.getItem('page') ?  toNumber(localStorage.getItem('page')) : 1
}

export const saveSearch = (scope: string, searchTerm: {[key: string]: any}) => {
  const dataSave = {...searchTerm}
  if (isObject(searchTerm)) {
    Object.keys(searchTerm).forEach((searchKey) => {
      const key = searchKey as keyof typeof searchTerm;
      if(searchKey == 'page') {
        saveSearchPage(searchTerm[key])
      }
      if (dayjs.isDayjs(searchTerm[key])) {
        dataSave[searchKey] = dayjs(searchTerm[key]).format(DATE_FORMAT);
      }
    });
  }
  const dataJSON = JSON.stringify({
    searchTerm: omit(dataSave, 'page'),
    scope
  });
  apiRequest.post({data: dataJSON,  url: '/api/admin/user-search'})
}

export const setSearch = async (scope: string, form: FormInstance) => {
  try {
    const data = await apiRequest.get({
      url: '/api/admin/user-search',
      params: { scope }
    });
    const searchTerm = get(data, 'data.data.searchTerm');
    const page = getSearchPage()

    if(isObject(searchTerm)) {
      Object.keys(searchTerm).forEach(key => {
          const value = get(searchTerm, key, '')
          if(dayjs(value, DATE_FORMAT, true).isValid()) {
            form.setFieldValue(key, dayjs(value, DATE_FORMAT))
          } else if (key === 'repairDate' && Array.isArray(value)) {
            const parsedDates = value.map(dateObj => dayjs(dateObj));
            form.setFieldValue(key, parsedDates);
          } else if (key === 'createdDate' && Array.isArray(value)) {
            const parsedDates = value.map(dateObj => dayjs(dateObj));
            form.setFieldValue(key, parsedDates);
          } else {
            form.setFieldValue(key, value)
          }
      })
    }
    form.setFieldValue('page', page)

    return data
  } catch (e) {
    console.log(e);
  }
};



export const setListDontHighLightMenu = (key: string, value: boolean, reset?: boolean) => {
  if(reset) {
    return localStorage.setItem(
      'listDontHighLight', '{}'
    );
  }
  const preValueJson = localStorage.getItem('listDontHighLight') || '{}'
  const preValue = JSON.parse(preValueJson)
  const valueNew =value? {...preValue, [key]: true} :  omit(preValue, key)
  localStorage.setItem(
    'listDontHighLight', JSON.stringify(valueNew)
  );

}

export const getListDontHighLightMenu = () => {
  const valueJson = localStorage.getItem('listDontHighLight') || '{}'
  return  JSON.parse(valueJson)
}
