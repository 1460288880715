import {
  Button,
  Checkbox,
  Form,
  Input,
  notification,
  Tree,
  TreeDataNode
} from 'core-ui';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from 'src/components/Footer';
import StyledForm from 'src/components/styled-form';
import { PermissionList } from 'src/enums/permission';
import { RoutePath } from 'src/enums/routePath';
import { systemColor } from 'src/theme';
import { apiRequest } from 'src/utils/api';
import { trimObjectValues } from 'src/utils/common';
import { ProfileContext } from 'src/contexts/profile';
import { PERMISSIONS } from '../../utils';

export default function ConfigController() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { fetchProfile } = useContext(ProfileContext);

  const onSubmit = async () => {
    let formData = form.getFieldsValue();
    formData.permissions = formData.permissions.filter(
      (key: string) => !key.includes('[parent]')
    );
    formData = trimObjectValues(formData);
    try {
      const { data } = await apiRequest.put({
        url: !id ? '/api/admin/positions' : `/api/admin/positions/${id}`,
        data: formData,
        method: id ? 'put' : 'post'
      });
      fetchProfile && fetchProfile();
      notification.success({ message: data.message });
      navigate(RoutePath.ADMIN + RoutePath.CONFIG);
    } catch (error: any) {
      notification.error({
        message:
          error.response?.data.message ||
          error.response?.data?.error?.[0].message
      });
    }
  };
  const fetchData = async () => {
    if (!id) return;
    const { data } = await apiRequest.get({
      url: `/api/admin/positions/${id}`
    });
    form.setFieldsValue(data.data);
    form.setFieldValue(
      'permissions',
      data.data.permissions.map((i: any) => i.permission)
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <StyledForm
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      style={{ background: 'rgb(242, 242, 242)' }}
    >
      <div className="bg-[#fff] h-[68px] pt-[12px] pb-[16px] px-[24px] ">
        <p className="font-[600] text-[24px]">
          {id ? '役割編集' : '役割新規作成'}
        </p>
      </div>
      <div className="bg-[#fff] mt-[16px] p-[16px] m-[20px]">
        <Input
          allowClear
          rules={[{ required: true, max: 255, whitespace: true }]}
          size="large"
          name="name"
          label="役割"
          style={{ maxWidth: 368 }}
          placeholder="役割"
        />
        <Form.Item
          rules={[{ required: true }]}
          initialValue={[]}
          label="権限設定"
          valuePropName="checkedKeys"
          name="permissions"
          validateTrigger="onCheck"
          trigger="onCheck"
          tooltip={{
            title: (
              <span style={{ maxWidth: 400 }}>
                一覧画面は基本的なアクセス権限です。
                <br />
                他の機能を選択すると、自動的に一覧画面の閲覧権限が与えられます。
              </span>
            ),
            overlayStyle: { maxWidth: 500 },
            placement: 'topRight'
          }}
        >
          <Tree
            selectable={false}
            defaultExpandAll
            checkable
            treeData={permissionTreeData}
          />
        </Form.Item>
      </div>
      <Footer>
        <Button
          style={{ height: 45, width: 168 }}
          onClick={() => navigate(RoutePath.ADMIN + RoutePath.CONFIG)}
        >
          {id ? 'キャンセル' : '戻る'}
        </Button>
        <Button
          style={{ height: 45, width: 168 }}
          type="primary"
          onClick={form.submit}
        >
          {id ? '保存' : '作成'}
        </Button>
      </Footer>
    </StyledForm>
  );
}

export const permissionTreeData: TreeDataNode[] = [
  {
    title: '全部選択',
    key: '[parent]',
    children: [
      {
        title: '顧客管理',
        key: '[parent]submit_agreement',
        children: [
          {
            title: PERMISSIONS[PermissionList.SUBMIT_AGREEMENT_LIST],
            key: PermissionList.SUBMIT_AGREEMENT_LIST
          },
          {
            title: PERMISSIONS[PermissionList.SUBMIT_AGREEMENT_SEARCH_LIST],
            key: PermissionList.SUBMIT_AGREEMENT_SEARCH_LIST
          },
          {
            title: PERMISSIONS[PermissionList.SUBMIT_AGREEMENT_CREATE],
            key: PermissionList.SUBMIT_AGREEMENT_CREATE
          },
          {
            title: PERMISSIONS[PermissionList.SUBMIT_AGREEMENT_EDIT],
            key: PermissionList.SUBMIT_AGREEMENT_EDIT
          },
          {
            title: PERMISSIONS[PermissionList.SUBMIT_AGREEMENT_DELETE],
            key: PermissionList.SUBMIT_AGREEMENT_DELETE
          }
        ]
      },
      {
        title: '顧客基本情報',
        key: '[parent]client',
        children: [
          {
            title: PERMISSIONS[PermissionList.CLIENT_LIST],
            key: PermissionList.CLIENT_LIST
          },
          {
            title: PERMISSIONS[PermissionList.CLIENT_SEARCH_LIST],
            key: PermissionList.CLIENT_SEARCH_LIST
          },
          {
            title: PERMISSIONS[PermissionList.CLIENT_CREATE],
            key: PermissionList.CLIENT_CREATE
          },
          {
            title: PERMISSIONS[PermissionList.CLIENT_DETAIL],
            key: PermissionList.CLIENT_DETAIL
          },
          {
            title: PERMISSIONS[PermissionList.CLIENT_EDIT],
            key: PermissionList.CLIENT_EDIT
          },
          {
            title: PERMISSIONS[PermissionList.CLIENT_DELETE],
            key: PermissionList.CLIENT_DELETE
          }
        ]
      },
      {
        title: '顧客プラン情報',
        key: '[parent]customer',
        children: [
          {
            title: PERMISSIONS[PermissionList.CUSTOMER_LIST],
            key: PermissionList.CUSTOMER_LIST
          },
          {
            title: PERMISSIONS[PermissionList.CUSTOMER_SEARCH_LIST],
            key: PermissionList.CUSTOMER_SEARCH_LIST
          },
          {
            title: PERMISSIONS[PermissionList.CUSTOMER_CREATE],
            key: PermissionList.CUSTOMER_CREATE
          },
          {
            title: PERMISSIONS[PermissionList.CUSTOMER_DETAIL],
            key: PermissionList.CUSTOMER_DETAIL
          },
          {
            title: PERMISSIONS[PermissionList.CUSTOMER_EDIT],
            key: PermissionList.CUSTOMER_EDIT
          },
          {
            title: PERMISSIONS[PermissionList.CUSTOMER_DELETE],
            key: PermissionList.CUSTOMER_DELETE
          }
        ]
      },
      {
        title: '修理管理',
        key: '[parent]repair',
        children: [
          { title: '修理一覧', key: PermissionList.REPAIR_LIST },
          { title: '修理新規作成', key: PermissionList.REPAIR_CREATE },
          { title: '修理詳細', key: PermissionList.REPAIR_DETAIL },
          { title: '修理編集', key: PermissionList.REPAIR_EDIT },
          { title: '修理削除', key: PermissionList.REPAIR_DELETE }
        ]
      },
      {
        title: 'アカウント管理',
        key: '[parent]account',
        children: [
          { title: 'アカウント一覧', key: PermissionList.ACCOUNT_LIST },
          { title: 'アカウント新規作成', key: PermissionList.ACCOUNT_CREATE },
          { title: 'アカウント詳細', key: PermissionList.ACCOUNT_DETAIL },
          { title: 'アカウント編集', key: PermissionList.ACCOUNT_EDIT },
          { title: 'アカウント削除', key: PermissionList.ACCOUNT_DELETE }
        ]
      },
      {
        title: '権限設定',
        key: '[parent]permission',
        children: [
          { title: '役割一覧', key: PermissionList.PERMISSION_LIST },
          {
            title: '役割新規作成',
            key: PermissionList.PERMISSION_CREATE
          },
          {
            title: '役割編集',
            key: PermissionList.PERMISSION_EDIT
          },
          {
            title: '役割削除',
            key: PermissionList.PERMISSION_DELETE
          }
        ]
      },
      {
        title: '全般設定',
        key: PermissionList.ENABLING_REPAIR_AGREEMENT_LINK
      },
      {
        title: 'メーカー名マスター',
        key: '[parent]maker',
        children: [
          { title: 'メーカー名一覧', key: PermissionList.MAKER_LIST },
          { title: 'メーカー名新規作成', key: PermissionList.MAKER_CREATE },
          { title: 'メーカー名編集', key: PermissionList.MAKER_EDIT },
          { title: 'メーカー名削除', key: PermissionList.MAKER_DELETE }
        ]
      },
      {
        title: '型番マスター',
        key: '[parent]model',
        children: [
          { title: '型番一覧', key: PermissionList.MODEL_LIST },
          { title: '型番新規作成', key: PermissionList.MODEL_CREATE },
          { title: '型番編集', key: PermissionList.MODEL_EDIT },
          { title: '型番削除', key: PermissionList.MODEL_DELETE }
        ]
      },
      {
        title: '製品カテゴリーマスター',
        key: '[parent]category',
        children: [
          { title: '製品カテゴリー一覧', key: PermissionList.CATEGORY_LIST },
          {
            title: '製品カテゴリー新規作成',
            key: PermissionList.CATEGORY_CREATE
          },
          { title: '製品カテゴリー編集', key: PermissionList.CATEGORY_EDIT },
          { title: '製品カテゴリー削除', key: PermissionList.CATEGORY_DELETE }
        ]
      }
    ]
  }
];
