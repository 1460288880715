import { TreeDataNode } from 'core-ui';
import { PermissionList } from 'src/enums/permission';
import { RoutePath } from 'src/enums/routePath';

export const PERMISSIONS = {
  [PermissionList.SUBMIT_AGREEMENT_LIST]: '顧客管理一覧',
  [PermissionList.SUBMIT_AGREEMENT_CREATE]: '顧客管理新規作成',
  [PermissionList.SUBMIT_AGREEMENT_EDIT]: '顧客管理編集',
  [PermissionList.SUBMIT_AGREEMENT_DELETE]: '顧客管理削除',
  [PermissionList.SUBMIT_AGREEMENT_SEARCH_LIST]: '顧客管理検索',
  [PermissionList.CUSTOMER_LIST]: '顧客プラン情報一覧',
  [PermissionList.CUSTOMER_SEARCH_LIST]: '顧客プラン情報検索',
  [PermissionList.CUSTOMER_CREATE]: '顧客プラン情報新規作成',
  [PermissionList.CUSTOMER_DETAIL]: '顧客プラン情報詳細',
  [PermissionList.CUSTOMER_EDIT]: '顧客プラン情報編集',
  [PermissionList.CUSTOMER_DELETE]: '顧客プラン情報削除',
  [PermissionList.CLIENT_LIST]: '顧客基本情報一覧',
  [PermissionList.CLIENT_SEARCH_LIST]: '顧客基本情報検索',
  [PermissionList.CLIENT_CREATE]: '顧客基本情報新規作成',
  [PermissionList.CLIENT_DETAIL]: '顧客基本情報詳細',
  [PermissionList.CLIENT_EDIT]: '顧客基本情報編集',
  [PermissionList.CLIENT_DELETE]: '顧客基本情報削除',
  [PermissionList.REPAIR_LIST]: '修理一覧',
  [PermissionList.REPAIR_CREATE]: '修理新規作成',
  [PermissionList.REPAIR_DETAIL]: '修理詳細',
  [PermissionList.REPAIR_EDIT]: '修理編集',
  [PermissionList.REPAIR_DELETE]: '修理削除',
  [PermissionList.ACCOUNT_LIST]: 'アカウント管理',
  [PermissionList.ACCOUNT_CREATE]: 'アカウント新規作成',
  [PermissionList.ACCOUNT_DETAIL]: 'アカウント詳細',
  [PermissionList.ACCOUNT_EDIT]: 'アカウント編集',
  [PermissionList.ACCOUNT_DELETE]: 'アカウント削除',
  [PermissionList.PERMISSION_LIST]: '役割一覧',
  [PermissionList.PERMISSION_CREATE]: '役割新規作成',
  [PermissionList.PERMISSION_EDIT]: '役割編集',
  [PermissionList.PERMISSION_DELETE]: '役割削除',
  [PermissionList.ENABLING_REPAIR_AGREEMENT_LINK]: '全般設定',
  [PermissionList.MAKER_LIST]: 'メーカー名一覧',
  [PermissionList.MAKER_CREATE]: 'メーカー名新規作成',
  [PermissionList.MAKER_EDIT]: 'メーカー名編集',
  [PermissionList.MAKER_DELETE]: 'メーカー名削除',
  [PermissionList.MODEL_LIST]: '型番一覧',
  [PermissionList.MODEL_CREATE]: '型番新規作成',
  [PermissionList.MODEL_EDIT]: '型番編集',
  [PermissionList.MODEL_DELETE]: '型番削除',
  [PermissionList.CATEGORY_LIST]: '製品カテゴリー一覧',
  [PermissionList.CATEGORY_CREATE]: '製品カテゴリー新規作成',
  [PermissionList.CATEGORY_EDIT]: '製品カテゴリー編集',
  [PermissionList.CATEGORY_DELETE]: '製品カテゴリー削除'
};
export const PERMISSION_FIRST_LOGIN_PATH = {
  [PermissionList.SUBMIT_AGREEMENT_LIST]:
    RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION,
  [PermissionList.SUBMIT_AGREEMENT_SEARCH_LIST]: '',
  [PermissionList.SUBMIT_AGREEMENT_CREATE]:
    RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION,
  [PermissionList.SUBMIT_AGREEMENT_EDIT]:
    RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION,
  // [PermissionList.SUBMIT_AGREEMENT_DETAIL]:
  //   RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION,
  [PermissionList.SUBMIT_AGREEMENT_DELETE]:
    RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION,
  [PermissionList.CUSTOMER_LIST]: RoutePath.ADMIN + RoutePath.CUSTOMERS,
  [PermissionList.CUSTOMER_CREATE]: RoutePath.ADMIN + RoutePath.CUSTOMERS,
  [PermissionList.CUSTOMER_DETAIL]: RoutePath.ADMIN + RoutePath.CUSTOMERS,
  [PermissionList.CUSTOMER_EDIT]: RoutePath.ADMIN + RoutePath.CUSTOMERS,
  [PermissionList.CUSTOMER_DELETE]: RoutePath.ADMIN + RoutePath.CUSTOMERS,
  [PermissionList.CUSTOMER_SEARCH_LIST]: RoutePath.ADMIN + RoutePath.CUSTOMERS,
  [PermissionList.CLIENT_LIST]: RoutePath.ADMIN + RoutePath.CLIENTS,
  [PermissionList.CLIENT_CREATE]: RoutePath.ADMIN + RoutePath.CLIENTS,
  [PermissionList.CLIENT_DETAIL]: RoutePath.ADMIN + RoutePath.CLIENTS,
  [PermissionList.CLIENT_EDIT]: RoutePath.ADMIN + RoutePath.CLIENTS,
  [PermissionList.CLIENT_DELETE]: RoutePath.ADMIN + RoutePath.CLIENTS,
  [PermissionList.CLIENT_SEARCH_LIST]: RoutePath.ADMIN + RoutePath.CLIENTS,
  [PermissionList.REPAIR_LIST]: RoutePath.REPAIRS,
  [PermissionList.REPAIR_CREATE]: RoutePath.REPAIRS,
  [PermissionList.REPAIR_DETAIL]: RoutePath.REPAIRS,
  [PermissionList.REPAIR_EDIT]: RoutePath.REPAIRS,
  [PermissionList.REPAIR_DELETE]: RoutePath.REPAIRS,
  [PermissionList.ACCOUNT_LIST]: RoutePath.ADMIN + RoutePath.ACCOUNT,
  [PermissionList.ACCOUNT_CREATE]: RoutePath.ADMIN + RoutePath.ACCOUNT,
  [PermissionList.ACCOUNT_DETAIL]: RoutePath.ADMIN + RoutePath.ACCOUNT,
  [PermissionList.ACCOUNT_EDIT]: RoutePath.ADMIN + RoutePath.ACCOUNT,
  [PermissionList.ACCOUNT_DELETE]: RoutePath.ADMIN + RoutePath.ACCOUNT,
  [PermissionList.PERMISSION_LIST]: RoutePath.ADMIN + RoutePath.CONFIG,
  [PermissionList.PERMISSION_CREATE]: RoutePath.ADMIN + RoutePath.CONFIG,
  [PermissionList.PERMISSION_EDIT]: RoutePath.ADMIN + RoutePath.CONFIG,
  // [PermissionList.PERMISSION_DETAIL]: RoutePath.ADMIN + RoutePath.CONFIG,
  [PermissionList.PERMISSION_DELETE]: RoutePath.ADMIN + RoutePath.CONFIG,
  [PermissionList.ENABLING_REPAIR_AGREEMENT_LINK]:
    RoutePath.ADMIN + RoutePath.CONFIG + RoutePath.EXPIRATION,
  [PermissionList.MAKER_LIST]: RoutePath.ADMIN + RoutePath.MAKERS,
  [PermissionList.MAKER_CREATE]: RoutePath.ADMIN + RoutePath.MAKERS,
  [PermissionList.MAKER_EDIT]: RoutePath.ADMIN + RoutePath.MAKERS,
  [PermissionList.MAKER_DELETE]: RoutePath.ADMIN + RoutePath.MAKERS,
  [PermissionList.MODEL_LIST]: RoutePath.ADMIN + RoutePath.MODELS,
  [PermissionList.MODEL_CREATE]: RoutePath.ADMIN + RoutePath.MODELS,
  [PermissionList.MODEL_EDIT]: RoutePath.ADMIN + RoutePath.MODELS,
  [PermissionList.MODEL_DELETE]: RoutePath.ADMIN + RoutePath.MODELS,
  [PermissionList.CATEGORY_LIST]: RoutePath.ADMIN + RoutePath.PRODUCTS,
  [PermissionList.CATEGORY_CREATE]: RoutePath.ADMIN + RoutePath.PRODUCTS,
  [PermissionList.CATEGORY_EDIT]: RoutePath.ADMIN + RoutePath.PRODUCTS,
  [PermissionList.CATEGORY_DELETE]: RoutePath.ADMIN + RoutePath.PRODUCTS
};
export const getAllNodes = (treeData: TreeDataNode[]) => {
  const result: TreeDataNode[] = [];

  const traverse = (node: TreeDataNode) => {
    result.push({ title: node.title, key: node.key });
    if (node.children) {
      node.children.forEach(traverse);
    }
  };

  treeData.forEach(traverse);
  return result;
};
