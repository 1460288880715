export enum PermissionList {
  // SEND_SUBMIT_AGREEMENT = 'send_submit_agreement',
  SUBMIT_AGREEMENT_LIST = 'submit_agreement_list',
  SUBMIT_AGREEMENT_CREATE = 'submit_agreement_create',
  // SUBMIT_AGREEMENT_DETAIL = 'submit_agreement_detail',
  SUBMIT_AGREEMENT_EDIT = 'submit_agreement_edit',
  SUBMIT_AGREEMENT_DELETE = 'submit_agreement_delete',
  SUBMIT_AGREEMENT_SEARCH_LIST = 'submit_agreement_search_list',


  CLIENT_LIST = 'client_list',
  CLIENT_CREATE = 'client_create',
  CLIENT_DETAIL = 'client_detail',
  CLIENT_EDIT = 'client_edit',
  CLIENT_DELETE = 'client_delete',
  CLIENT_SEARCH_LIST = 'client_search_list',

  CUSTOMER_LIST = 'customer_list',
  CUSTOMER_CREATE = 'customer_create',
  CUSTOMER_DETAIL = 'customer_detail',
  CUSTOMER_EDIT = 'customer_edit',
  CUSTOMER_DELETE = 'customer_delete',
  CUSTOMER_SEARCH_LIST = 'customer_search_list',

  REPAIR_LIST = 'repair_list',
  REPAIR_CREATE = 'repair_create',
  REPAIR_DETAIL = 'repair_detail',
  REPAIR_EDIT = 'repair_edit',
  REPAIR_DELETE = 'repair_delete',

  ACCOUNT_LIST = 'account_list',
  ACCOUNT_CREATE = 'account_create',
  ACCOUNT_DETAIL = 'account_detail',
  ACCOUNT_EDIT = 'account_edit',
  ACCOUNT_DELETE = 'account_delete',

  PERMISSION_LIST = 'permission_list',
  PERMISSION_CREATE = 'permission_create',
  // PERMISSION_DETAIL = 'permission_detail',
  PERMISSION_EDIT = 'permission_edit',
  PERMISSION_DELETE = 'permission_delete',

  ENABLING_REPAIR_AGREEMENT_LINK = 'enabling_repair_agreement_link',

  MAKER_LIST = 'maker_list',
  MAKER_CREATE = 'maker_create',
  MAKER_EDIT = 'maker_edit',
  MAKER_DELETE = 'maker_delete',

  MODEL_LIST = 'model_list',
  MODEL_CREATE = 'model_create',
  MODEL_EDIT = 'model_edit',
  MODEL_DELETE = 'model_delete',

  CATEGORY_LIST = 'category_list',
  CATEGORY_CREATE = 'category_create',
  CATEGORY_EDIT = 'category_edit',
  CATEGORY_DELETE = 'category_delete'
}
